import { ENTITY_TYPES, KINDS } from "constants/projects";
import { ISprint } from "store/types/backlog";
import { KanbanElement, KanbanScreen } from "store/types/kanban";

export const updateArray = (originalArray: ISprint[], updatedArray: ISprint[]): ISprint[] => {
  const origArray = Array.from(originalArray);
  const updatedMap = new Map(updatedArray.map((item) => [item.id, item]));

  return origArray.map((item) => {
    const updatedItem = updatedMap.get(item.id);
    if (updatedItem) {
      return updatedItem;
    }
    return item;
  });
};

export const move = (
  source: (KanbanScreen | KanbanElement)[],
  destination: (KanbanScreen | KanbanElement)[],
  droppableSource: any,
  droppableDestination: any,
): { [key: string]: (KanbanScreen | KanbanElement)[] } => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  };
};

export const getEntities = (items: (KanbanScreen | KanbanElement)[]) => {
  const screens = items.filter((item) => item.item_type === ENTITY_TYPES.screen).map((el) => el.id);
  const elements = items
    .filter((item) => item.item_type === ENTITY_TYPES.element)
    .map((el) => el.id);

  return { screens, elements };
};

export const reorder = (
  list: KanbanScreen[],
  startIndex: number,
  endIndex: number,
): KanbanScreen[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const replaceItemInSprint = (sprints: ISprint[], item: KanbanScreen | KanbanElement) => {
  const currentSprint = sprints.find((el) => el.id === item.parentSprint);

  if (currentSprint) {
    if ("kind" in item && (item.kind === KINDS.element || item.kind === KINDS.subtask)) {
      const parentScreen = currentSprint.items.find(
        (screen) => screen.id === item.parentScreen,
      ) as KanbanScreen;
      const updatedScreenElements = parentScreen.elements.map((el) =>
        el.id === item.id ? item : el,
      );
      const updatedScreen = { ...parentScreen, elements: updatedScreenElements };
      const newSprintItems = currentSprint.items.map((el) =>
        el.id === updatedScreen.id ? updatedScreen : el,
      );
      return { ...currentSprint, items: newSprintItems };
    }
    const newSprintItems = currentSprint.items.map((el) => (el.id === item.id ? item : el));

    return { ...currentSprint, items: newSprintItems };
  }
  return sprints[0];
};

export const getBacklogDescendant = (sprints: ISprint[]) => {
  const backlog = sprints.find((sprint) => sprint.id === 0);
  const allItems = sprints.flatMap((sprint) =>
    sprint.items.flatMap((item) => {
      if ("elements" in item) {
        const elements = item.elements.map((element) => {
          if (element.kind === KINDS.bug) {
            return {
              ...element,
              parentSprint:
                backlog && backlog?.items.some((item) => item.id === element.id)
                  ? backlog.id
                  : sprint.id,
              parentScreen: item.id,
            };
          }
          return { ...element, parentSprint: sprint.id, parentScreen: item.id };
        });
        const screenWithBugs = { ...item, parentSprint: sprint.id } as KanbanScreen;
        const newScreen = excludeBugsAsChildren([screenWithBugs]);
        return [newScreen, ...elements].flat();
      }
      return { ...item, parentSprint: sprint.id };
    }),
  ) as (KanbanScreen | KanbanElement)[];
  return allItems;
};

export const excludeBugsAsChildren = (items: (KanbanScreen | KanbanElement)[]) => {
  const newItems = items.map((item) =>
    "elements" in item
      ? { ...item, elements: item.elements.filter((el) => el.kind !== KINDS.bug) }
      : item,
  );
  return newItems;
};

export const getCorrectParent = (item: KanbanScreen | KanbanElement, parentSprint: number) => {
  if (item.item_type === ENTITY_TYPES.screen && "elements" in item) {
    const newElements = item.elements.map((el) => ({ ...el, parentScreen: item.id, parentSprint }));
    return { ...item, elements: newElements };
  }
  return { ...item, parentSprint };
};

export const formatNames = (items: (KanbanScreen | KanbanElement)[]) => {
  const names = items.map((obj) => obj.name);
  if (names.length > 3) {
    return `${names.slice(0, 3).join(", ")}, ...`;
  }
  return names.join(", ");
};
