export const API = {
  // auth
  login: () => "/auth/login/",
  logout: () => "/auth/logout/",
  singUp: () => "/auth/signup/",
  resetPassword: () => "/auth/password/reset/",
  resetPasswordConfirm: () => "/auth/password/reset/confirm/",
  changePassword: () => "/auth/password/change/",
  resendEmail: () => "/auth/registration/resend-email/",
  confirmEmail: () => "/auth/account-confirm-email/",
  // users
  profileUpdate: (userId: string) => `/users/profile/${userId}/`,
  userData: (userId: string) => `/users/profile/${userId}/`,
  // projects
  projectsList: () => `/projects/`,
  createProject: () => `/projects/`,
  editProject: (projectId: number) => `/projects/${projectId}/`,
  deleteProject: (projectId: number) => `/projects/${projectId}/`,
  generateSlug: () => `/projects/generate-slug/`,
  validateSlug: () => `/projects/validate-slug/`,
  // project
  getProjectDetails: (projectId: number) => `/projects/${projectId}/`,
  exportPDF: (projectId: number) => `/projects/${projectId}/export/`,
  exportTimeLog: (projectId: number) => `/projects/${projectId}/export-time-log/`,
  getProjectFigmaImportStatus: (projectId: number) =>
    `/projects/${projectId}/check-figma-import-status/`,
  getTaskStatus: () => "/core/get-task-status/",
  moveItems: (projectId: number) => `/projects/${projectId}/move-items/`,
  duplicateItems: (projectId: number) => `/projects/${projectId}/duplicate-items/`,
  deleteMultiple: (projectId: number) => `/projects/${projectId}/bulk-delete/`,
  // screens
  createScreen: (projectId: number) => `/projects/${projectId}/create-screen/`,
  uploadScreens: (projectId: number) => `/projects/${projectId}/upload-screens/`,
  updateScreen: (slug: string) => `/projects/screens/${slug}/`,
  deleteScreen: (projectId: number) => `/projects/screens/${projectId}/`,
  mergeScreens: (projectId: number) => `/projects/${projectId}/merge-screens/`,
  screenLogTime: (slug: string) => `/projects/screens/${slug}/log-time/`,
  screenDetails: (screenSlug: string) => `/projects/screens/${screenSlug}/`,
  readScreenUpdate: (projectId: number) => `/projects/${projectId}/read-screen-update/`,
  generateScreenReq: (slug: string) => `/projects/screens/${slug}/ai-generate/`,
  getScreenTimeLog: (slug: string) => `projects/screens/${slug}/list-time-logs/`,
  // group
  createGroup: (projectId: number) => `/projects/${projectId}/create-group/`,
  deleteGroup: (groupId: number) => `/projects/screen-groups/${groupId}/`,
  updateGroup: (slug: string) => `/projects/screen-groups/${slug}/`,
  groupLogTime: (slug: string) => `/projects/screen-groups/${slug}/log-time/`,
  groupDetails: (groupSlug: string) => `/projects/screen-groups/${groupSlug}/`,
  getGroupTimeLog: (slug: string) => `projects/screen-groups/${slug}/list-time-logs/`,

  // element
  markElement: (screenSlug: string) => `/projects/screens/${screenSlug}/mark-element/`,
  updateElement: (slug: string) => `/projects/elements/${slug}/`,
  deleteElement: (elementId: number) => `/projects/elements/${elementId}/`,
  elementLogTime: (slug: string) => `/projects/elements/${slug}/log-time/`,
  elementDetails: (elementSlug: string) => `/projects/elements/${elementSlug}/`,
  getElementTimeLog: (slug: string) => `projects/elements/${slug}/list-time-logs/`,
  uploadAttachments: (slug: string) => `projects/elements/${slug}/upload-attachment/`,
  deleteAttachment: (id: number) => `projects/attachments/${id}/`,

  // figma
  getFigmaToken: () => "/users/profile/get-figma-token/",
  setFigmaState: () => "/users/profile/set-figma-state/",
  getFigmaFilePages: () => "/projects/get-figma-file-pages/",
  getFigmaImages: () => "/projects/get-figma-images/",
  checkFigmaToken: (userId: string) => `/users/profile/${userId}/check-figma-token/`,
  importScreensFigma: (projectId: number) => `/projects/${projectId}/import-screens-figma/`,

  // Workspace
  getWorkspaceList: () => "/users/workspaces/",
  createWorkspace: () => "/users/workspaces/",
  getWorkspaceDetails: (orgId: number) => `/users/workspaces/${orgId}/`,
  updateWorkspace: (orgId: number) => `/users/workspaces/${orgId}/`,
  inviteUsers: (orgId: number) => `/users/workspaces/${orgId}/invite/`,
  removeUser: (orgId: number) => `/users/workspaces/${orgId}/remove-user/`,
  changeRole: (orgId: number) => `/users/workspaces/${orgId}/change-role/`,
  changeProjects: (orgId: number) => `/users/workspaces/${orgId}/change-projects/`,
  acceptInvite: () => "/users/workspaces/accept-invite/",
  checkInviteKey: () => "/users/workspaces/check-workspace-invite-key/",

  // kanban
  getKanbanData: (id: number) => `/projects/${id}/board/`,
  getKanbanSprintData: (id: number) => `/projects/${id}/board-v2/`,
  getColumns: (id: number) => `/projects/${id}/statuses-list/`,

  //sprints
  backlogAPI: (projectId?: number) => `projects/${projectId}/backlog/`,
  getSprints: (id: number) => `projects/${id}/sprints/`,
  createSprints: () => `projects/sprints/`,
  currentSprintAPI: (id: number) => `projects/sprints/${id}/`,
  moveToSprint: (id: number) => `projects/sprints/${id}/dnd-backlog/`,

  // comments
  editDeleteComment: (commentId: number) => `projects/comments/${commentId}/`,
  sendGroupComment: (slug: string) => `projects/screen-groups/${slug}/post-comment/`,
  sendScreenComment: (slug: string) => `projects/screens/${slug}/post-comment/`,
  sendElementComment: (slug: string) => `projects/elements/${slug}/post-comment/`,

  // push notifications
  sendFirebaseToken: () => `users/devices/`,

  //timelogs

  editDeleteTimeLog: (id: number) => `projects/time-logs/${id}/`,
};
